import React, {useEffect, useState} from "react";
import {Site, SoofieSite, VectorFeatureLayerProps} from "../types";
import {fieldMapApiSlice} from "../fieldMapApiSlice";
import {useAppSelector} from "../../../app/hooks";
import {MyVectorLayer} from "../../common/VectorLayer";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import SiteLabelFeature from "./feature/SiteLabelFeature";
import {fromLonLat, get} from "ol/proj";
import LineFeature from "./feature/LineFeature";
import Vector2D from "../../../Vector2D";
import config from "../../../app/config";
import {log_interpolate_range} from "../../../interpolate";
import GeoJSON from "ol/format/GeoJSON";

let styles = {
    'MultiPolygon': new Style({
        stroke: new Stroke({
            color: 'blue',
            width: 1
        }),
        fill: new Fill({
            color: "rgba(0,0,255,0.2)"
        })
    })
}

export const FieldMapFeaturesLayer: React.FC<VectorFeatureLayerProps> = ({zIndex, features, zoom}: VectorFeatureLayerProps) => {

    const userOrganizationId = useAppSelector(state => state.auth.organizationId) as string

    const { data: fieldMap, isLoading: isFieldMapLoading } = fieldMapApiSlice.endpoints.fetchFieldMap.useQuery(userOrganizationId);

    const [fieldMapFeatures, setFieldMapFeatures] = useState({})

    useEffect(() => {

        if (!fieldMap || !zoom) {
            return;
        }

        const fieldMapCenterMetersCoordinate = fromLonLat(fieldMap.map_json.center)
        const soofie = fieldMap.map_json.soofie_sites ? fieldMap.map_json.soofie_sites : []
        const newFieldMapFeatures = [...soofie, ...fieldMap.map_json.sites].flatMap((site: Site | SoofieSite) => {
            let features = [];
            let siteNodeLonLatCoordinate = site.siteNodeCoordinate

            let labelNodeLonLatCoordinate = site.gaugeNodeCoordinate
            let reflectorNode, openpath;

            let labelAnchorMetersCoordinate = fromLonLat(siteNodeLonLatCoordinate)
            let labelNodeMetersCoordinate = fromLonLat(labelNodeLonLatCoordinate)

            if(site.isOpenPath && site.reflectorNodeCoordinate) {
                reflectorNode = site.reflectorNodeCoordinate
                openpath = LineFeature(
                    fromLonLat(siteNodeLonLatCoordinate),
                    fromLonLat(reflectorNode),
                    [
                        new Style({
                            stroke: new Stroke({
                                color: 'white',
                                width: 11,
                            }),
                        }),
                        new Style({
                            stroke: new Stroke({
                                color: 'rgba(77,150,77,1)',
                                width: 6,
                            }),
                        }),
                    ]
                )
                features.push(openpath);
                labelAnchorMetersCoordinate = openpath.getGeometry().getFlatMidpoint()
            }

            let centeringCoordinate = fieldMapCenterMetersCoordinate;
            if(site.centeringCoordinate) {
                centeringCoordinate = fromLonLat(site.centeringCoordinate);
            }

            let vFieldMapCenterToAnchor = new Vector2D(labelAnchorMetersCoordinate[0], labelAnchorMetersCoordinate[1])
                .diff(new Vector2D(centeringCoordinate[0], centeringCoordinate[1]))

            // normalize it to get a unit vector in the direction
            let vHatAnchorToLabel = vFieldMapCenterToAnchor.normalized()

            // get the original magnitude, which we'll use as the maximum
            let vAnchorToLabelMagnitudeMax = vFieldMapCenterToAnchor.length()

            let vAnchorToLabelMagnitude = log_interpolate_range(config.MIN_ZOOM, config.MAX_ZOOM, 750, .1, zoom)

            let vAnchorToLabelFinal = new Vector2D(labelNodeMetersCoordinate[0], labelNodeMetersCoordinate[1])
                .sum(vHatAnchorToLabel.scaled(vAnchorToLabelMagnitude))

            // line to label
            features.push(LineFeature(
                labelAnchorMetersCoordinate,
                [labelNodeMetersCoordinate[0], labelNodeMetersCoordinate[1]],
                new Style({
                    stroke: new Stroke({
                        color: '#737f7b',
                        width: 2
                    })
                })
            ))

            // label
            features.push(SiteLabelFeature(
                [labelNodeMetersCoordinate[0], labelNodeMetersCoordinate[1]],
                null,
                site.abbrName,
                zoom
            ))

            return features
        })

        // @TODO look into  ol.module.Collection
        setFieldMapFeatures(newFieldMapFeatures)
    }, [fieldMap, zoom])

    return (
        <MyVectorLayer
            layerName={"fieldMapFeatures"}
            features={fieldMapFeatures}
            zIndex={zIndex}
            styles={styles.MultiPolygon}
        />)

}